import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'sdp-small-button',
  templateUrl: './small-button.component.html',
  styleUrls: ['./small-button.component.scss']
})
export class SmallButtonComponent {

  @Output() smallBtnEmitter = new EventEmitter<null>();
  @Input() icon: string;
  @Input() title: string;

  clickEmit(): void {
    this.smallBtnEmitter.emit();
  }
}
