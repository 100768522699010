import {Injectable} from '@angular/core';
import {from, Observable} from 'rxjs';
import {HttpConfigService} from '../../../data/http/http-config.service';
import {HttpHeaders} from '@angular/common/http';
import {HttpSdpService} from '../../../data/http/http-sdp.service';
import {PRODUCT_API} from '../../../shared/Constants';

@Injectable({
  providedIn: 'root'
})
export class ProductInfoService {

  constructor(private httpConfig: HttpConfigService,
              private httpSdpService: HttpSdpService) {
  }

  getProductInfo(productData: any): Promise<Observable<any>> {
    return this.httpSdpService.configSdpHeaders().then(headers => {
      return this.httpConfig.httpRequest(
        'get',
        `${PRODUCT_API}${productData.productId}?subsidiary=${productData.countryCode}&language=${productData.language}&sales_line=${productData.salesLine}`,
        null,
        headers);
    });
  }
}
