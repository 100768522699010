import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {ArticleMockDataService} from '../../../../data/http/utils/article-mock-data.service';
import {HttpSdpService} from '../../../../data/http/http-sdp.service';
import {ClientArticleDTO} from '../../../dto/client-article-dto';
import {UiErrorInterceptor} from '../../../../service/ui-error-interceptor';
import {FormDataDTO} from './dto/form-data-interface';
import {Observable, Subscription} from 'rxjs';
import {take} from 'rxjs/operators';

@Component({
  selector: 'sdp-xml-form',
  templateUrl: './xml-form.component.html',
  styleUrls: ['./xml-form.component.scss']
})
export class XmlFormComponent implements OnInit {
  formData: FormDataDTO;
  private getArticleXmlSubscription: Subscription;
  clientArticleDTO: ClientArticleDTO;
  @Output() formDataEmitter: EventEmitter<FormDataDTO>;

  constructor(private articleDataService: ArticleMockDataService,
              private httpSdpService: HttpSdpService,
              private alertService: UiErrorInterceptor) {
    this.clientArticleDTO = new ClientArticleDTO();
    this.formDataEmitter = new EventEmitter<FormDataDTO>();
  }

  ngOnInit(): void {
  }

  getArticleXml(): void {
    if (!this.clientArticleDTO
      || !this.clientArticleDTO.productId
      || !this.clientArticleDTO.outletId
      || !this.clientArticleDTO.labelId
      || !this.clientArticleDTO.templateName) {

      this.alertService.setUiError({message: `Message: Please fill all form fields.`, class: 'snackbar-error'});
      return;
    }
    this.httpSdpService.getArticleXML(this.clientArticleDTO).then((req: Observable<object>) => {
      req.subscribe((resp: any) => {
        this.formData = new FormDataDTO();
        this.formData.outletId = this.clientArticleDTO.outletId;
        this.formData.xmlData = resp;
        this.formDataEmitter.emit(this.formData);
        this.alertService.setUiError({message: 'XML generated with success', class: 'snackbar-success'});
      });
    });
  }
}
