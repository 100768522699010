<div class="container">
  <div class="content-center flex-sp-btn">
    <sdp-label-search class="label-search-container"
                      (campaignGetEmitter)="getCampaignByProductIdAndOutletId($event)"
                      (labelSearchEmitter)="getLinkByLabelId($event)"
                      (labelStatusEmitter)="getStatusOfLabel($event)"
                      (productInfoEmitter)="getProductInfo($event)"
                      (priceInfoEmitter)="getPriceInfo($event)"
                      (warrantyEmitter)="getWarrantiesBy($event)"
                      (liveLabelEmitter)="getLiveLabelByLabelId($event)"
                      (searchByProductOutletCountryEmitter)="getLinksByProductIdAndOrOutletIdAndOrCountryId($event)">

    </sdp-label-search>
    <sdp-label-data-display [data]="requestResponse" [image]="imageResponse" class="label-data-container"></sdp-label-data-display>
  </div>
</div>

