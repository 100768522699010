export class TemplateDTO {
  public content: string;
  public country: string;
  public height: number;
  public id: number;
  public name: string;
  public type: string;
  public width: number;
  public mandatoryProducts: number;

  constructor(name: string, content: string, width: number, height: number, type: TemplateType, country: string,
              mandatoryProducts: number) {
    this.name = name;
    this.content = content;
    this.width = width;
    this.height = height;
    this.type = type;
    this.country = country;
    this.mandatoryProducts = mandatoryProducts;
  }
}

export enum TemplateType {
  blister_template = "blister_template",
  set_template = "set_template",
  accessory_template = "accessory_template",
  variant_template = "variant_template",
  service_template = "service_template",
  basic_template = "basic_template",
  multi_product_template = "multi_product_template"
}
