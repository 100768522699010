import {Injectable} from '@angular/core';
import {HttpConfigService} from '../../../data/http/http-config.service';
import {HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {PRICE_API} from '../../../shared/Constants';

@Injectable({
  providedIn: 'root'
})
export class PriceInfoService {

  constructor(private httpConfig: HttpConfigService) {
  }

  // todo create a header config file and set headers only there
  configSdpHeaders(responseType = null): any {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-debug-flag', 'test')
      .set('x-api-key', 'Rpz1EoTqWeYiARGNE0zIsjwJj9WGza2w');
    return {
      headers,
      responseType
    };
  }

  getPriceInfo(priceData: any): Observable<any> {
    return this.httpConfig.httpRequest(
      'get',
      `${PRICE_API}?outlet_id=${priceData.outletId}&product_ids=${priceData.productId}&product_id_type=${priceData.productIdType}`,
      null,
      this.configSdpHeaders());
  }
}
