<div class="dropdown">
  <button mat-button (click)="toggleShowDropdown()">
    <span>{{selectedValue}}</span>
    <mat-icon role="img" class="mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true">
      arrow_drop_down
    </mat-icon>
  </button>
  <div class="dropdown-content" #dropdownElement>
    <a *ngFor="let content of dropdownContent" (click)="selectValue(content)">{{content}}</a>
  </div>
</div>
