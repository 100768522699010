export class ProductDTO {
  private product_id: number;
  private subsidiary: string;

  getProductId(): number {
    return this.product_id;
  }

  setProductId(value: number): ProductDTO {
    this.product_id = value;
    return this;
  }

  getSubsidiary(): string {
    return this.subsidiary;
  }

  setSubsidiary(value: string): ProductDTO {
    this.subsidiary = value;
    return this;
  }
}
