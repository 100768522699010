import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';

import {AppComponent} from './app.component';
import {MainPageComponent} from './ui/main-page/main-page.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {DemoMaterialModule} from './material-module';
import {FormsModule} from '@angular/forms';
import {NavBarComponent} from './ui/shared-components/nav-bar/nav-bar.component';
import {TemplateUploadComponent} from './ui/template-upload/template-upload.component';
import {TemplateDisplayComponent} from './ui/template-upload/template-display/template-display.component';
import {TemplateCardComponent} from './ui/template-upload/template-display/template-card/template-card.component';
import {UploadComponent} from './ui/shared-components/upload/upload.component';
import {SmallButtonComponent} from './ui/shared-components/small-button/small-button.component';
import {SdpButtonComponent} from './ui/shared-components/sdp-button/sdp-button.component';
import {routing} from './routing/sdp-routes';
import {PageNotFoundComponent} from './ui/shared-components/page-not-found/page-not-found.component';
import {HttpInterceptorClass} from './data/error-handler/http-interceptor';
import {AlertMessageComponent} from './ui/shared-components/alert-message/alert-message.component';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import { AssignTemplateComponent } from './ui/assign-template/assign-template.component';
import { XmlGeneratorComponent } from './ui/template-upload/xml-generator/xml-generator.component';
import { XmlFormComponent } from './ui/template-upload/xml-generator/xml-form/xml-form.component';
import { DropdownComponent } from './ui/shared-components/dropdown/dropdown.component';
import {XmlPipe} from './service/xml-pipe';
import {SafeHtmlPipe} from './service/safeHtml/safe-html-pipe.service';
import {SafeHtmlPipeService} from './service/safe-html-pipe.service';
import {ClickOutsideDirective} from './service/click-outside-directive/click-outside-directive';
import { LabelsComponent } from './ui/labels/labels.component';
import { LabelSearchComponent } from './ui/labels/label-search/label-search.component';
import { LabelDataDisplayComponent } from './ui/labels/label-data-display/label-data-display.component';

@NgModule({
  entryComponents: [AlertMessageComponent],
  declarations: [
    AppComponent,
    MainPageComponent,
    NavBarComponent,
    TemplateUploadComponent,
    TemplateDisplayComponent,
    TemplateCardComponent,
    UploadComponent,
    SmallButtonComponent,
    SdpButtonComponent,
    SafeHtmlPipeService,
    PageNotFoundComponent,
    AlertMessageComponent,
    AssignTemplateComponent,
    XmlGeneratorComponent,
    XmlFormComponent,
    DropdownComponent,
    XmlPipe,
    SafeHtmlPipe,
    ClickOutsideDirective,
    LabelsComponent,
    LabelSearchComponent,
    LabelDataDisplayComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    DemoMaterialModule,
    FormsModule,
    routing
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorClass,
      multi: true
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {appearance: 'fill'}
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
