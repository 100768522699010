import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

/*
 * Insert safe URL/HTML
 * Usage:
 *   value | safeHtml
*/

@Pipe({name: 'safeHtml'})
export class SafeHtmlPipeService implements PipeTransform {

  constructor(private sanitized: DomSanitizer) {
  }

  transform(value): SafeHtml {
    return this.sanitized.bypassSecurityTrustUrl(value);
  }
}
