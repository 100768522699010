import {Injectable} from '@angular/core';
import {HttpConfigService} from './http-config.service';

import {HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {RENDERING_URL} from '../../shared/Constants';
import {ImagePreviewClientDTO} from '../../ui/template-upload/dto/ImagePreviewClientDTO';


@Injectable({
  providedIn: 'root'
})
export class HttpRenderingEngineService {
  constructor(private httpConfigService: HttpConfigService) {
  }

  configReHeaders(): any {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Basic cHJlc3RvOnByZXN0b1JF');

    return {
      headers,
      responseType: 'arrayBuffer'
    };
  }

  getImagePreview(imagePreviewPayload: ImagePreviewClientDTO): Observable<any> {
    return this.httpConfigService.httpRequest(
      'post',
      RENDERING_URL + 'label/render_provided',
      imagePreviewPayload,
      this.configReHeaders());
  }
}
