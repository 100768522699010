import {Component, ElementRef, EventEmitter, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'sdp-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent {
  @ViewChild('uploadInput') uploadInput: ElementRef<HTMLElement>;
  @Output() fileEmmiter: EventEmitter<any>;

  constructor() {
    this.fileEmmiter = new EventEmitter<string>();
  }

  uploadFile(): void {
    this.uploadInput.nativeElement.click();
  }

  readFile(event): void {
    const reader = new FileReader();
    reader.readAsText(event.target.files[0]);
    reader.onloadend = () => {
      const htmlTemplate = reader.result as string;
      this.fileEmmiter.emit({htmlContent: htmlTemplate, templatePath: event.target.value});
      event.target.value = '';
    };
  }
}
