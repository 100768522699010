import {Component, OnChanges, SimpleChanges} from '@angular/core';
import {TemplateUploadDTO} from '../../dto/template-upload-dto';
import {TemplateListService} from '../services/template-list.service';

@Component({
  selector: 'sdp-template-display',
  templateUrl: './template-display.component.html',
  styleUrls: ['./template-display.component.scss']
})
export class TemplateDisplayComponent implements OnChanges {
  templates: TemplateUploadDTO[];

  constructor(private templateService: TemplateListService) {
    this.templates = [new TemplateUploadDTO('MM_at_2020', 'https://images.unsplash.com/photo-1494548162494-384bba4ab999?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80')];
    this.templateService.nextTemplateList(this.templates);
  }

  ngOnChanges(changes: SimpleChanges): void {
    // todo : change with async in the future
    this.templateService.templateListObs$.subscribe((templateList) => {
    });
  }
}
