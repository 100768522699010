import {PriceInformationDTO} from './price-information-dto';
import {ProductInformationDTO} from './product-information-dto';

export class ArticleDTO {
  private price: PriceInformationDTO;
  private product: ProductInformationDTO;

  getPriceInformationDTO(): PriceInformationDTO {
    return this.price;
  }

  setPriceInformationDTO(value: PriceInformationDTO): ArticleDTO {
    this.price = value;
    return this;
  }

  getProductInformationDTO(): ProductInformationDTO {
    return this.product;
  }

  setProductInformationDTO(value: ProductInformationDTO): ArticleDTO {
    this.product = value;
    return this;
  }
}
