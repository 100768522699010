import {BehaviorSubject, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {IAlerts} from '../ui/interfaces/ialerts';

@Injectable({
  providedIn: 'root'
})
export class UiErrorInterceptor {
  // todo: create a codee and error msg?
  private uiErrorSubject: BehaviorSubject<IAlerts>;
  public uiError: Observable<IAlerts>;

  constructor() {
    this.uiErrorSubject = new BehaviorSubject<IAlerts>(null);
    this.uiError = this.uiErrorSubject.asObservable();
  }

  setUiError(alert: IAlerts): void {
     this.uiErrorSubject.next(alert);
  }
}
