import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {IAlerts} from '../ui/interfaces/ialerts';

@Injectable({
  providedIn: 'root'
})
export class DisplayErrorService {

  private errorMessageSubject: BehaviorSubject<any>;
  public errorMessage: Observable<any>;

  constructor() {
    this.errorMessageSubject = new BehaviorSubject<IAlerts>(null);
    this.errorMessage = this.errorMessageSubject.asObservable();
  }

  setError(alert: any): void {
    this.errorMessageSubject.next(alert);
  }
}
