import {Directive, ElementRef, EventEmitter, Output} from '@angular/core';

@Directive({
  selector: '[sdpClickOutside]'
})
export class ClickOutsideDirective {
  // invoked everytime a click was made outside
  @Output() sdpClickOutside = new EventEmitter<boolean>();

  constructor(private elementRef: ElementRef) {
  }

  // listens for certain events on the host(document)
  // @HostListener('document:click', ['$event.target'])
  public onClick(targetElement): void {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.sdpClickOutside.emit(this.elementRef.nativeElement);
    }
  }

}
