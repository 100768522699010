<mat-toolbar>
  <mat-toolbar-row>
    <div class="content-center flex">
      <div class="nav-link">
        <a routerLink="" routerLinkActive="route--active">Template Upload</a>
      </div>
      <div class="nav-link"><a routerLink="/labels">ESL-Data</a></div>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
