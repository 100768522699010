import {Injectable} from '@angular/core';
import {TemplateUtilService} from './template-util';
import {UiErrorInterceptor} from '../../../service/ui-error-interceptor';
import {SDP_TXT} from '../../../shared/Constants';
import {ImagePreviewClientDTO} from '../dto/ImagePreviewClientDTO';
import {TemplateDTO} from '../../../data/http/dto/template-dto';

@Injectable({
  providedIn: 'root'
})
export class TemplateUploadService {
  constructor(private templateUtilService: TemplateUtilService,
              private alertMessage: UiErrorInterceptor) {
  }

  checkForSaveTemplatePayload(name: string, templateDTO: TemplateDTO): any {
    if (!templateDTO.width || !templateDTO.height) {
      this.alertMessage.setUiError({
        message: SDP_TXT.templateUpload.missingDimensions,
        class: 'snackbar-error'
      });
      return null;
    }
    if (!templateDTO.type) {
      this.alertMessage.setUiError({
        message: SDP_TXT.templateUpload.missingTemplateType,
        class: 'snackbar-error'
      });
      return null;
    }
    if (!name || !templateDTO.content) {
      this.alertMessage.setUiError({
        message: SDP_TXT.templateUpload.missingNameOrTemplate,
        class: 'snackbar-error'
      });
      return null;
    }
    if (!templateDTO.country || templateDTO.country === 'None') {
      this.alertMessage.setUiError({
        message: SDP_TXT.templateUpload.missingCountry,
        class: 'snackbar-error'
      });

      return null;
    }
    return true;
  }

  isImagePayloadInValid(imageRenderPayload: ImagePreviewClientDTO): string | boolean {
    let error = '';
    if (!imageRenderPayload.xmlContent || !imageRenderPayload.xmlContent.trim()) {
      error = SDP_TXT.renderImage.missingXmlContent;
    } else if (!imageRenderPayload.outletId) {
      error = SDP_TXT.renderImage.missingOutletId;
      imageRenderPayload.outletId = 1015;
    } else if (!imageRenderPayload.htmlTemplate) {
      error = SDP_TXT.renderImage.missingHtmlFile;
    } else {
      return false;
    }
    return error;
  }

  getAndCheckTemplateDimensionsAndType(template: string): any {
    const dimensions = this.templateUtilService.extractDimensionFromTemplate(template);
    const templateType = this.templateUtilService.searchAndSetTemplateType(template);
    if (!dimensions) {
      this.alertMessage.setUiError({
        message: SDP_TXT.templateUpload.missingDimensions,
        class: 'snackbar-error'
      });
      return null;
    }
    return {dimensions, templateType};
  }
}
