import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {TemplateUploadDTO} from '../../dto/template-upload-dto';

@Injectable({
  providedIn: 'root'
})
export class TemplateListService {
  private templateList = new BehaviorSubject<TemplateUploadDTO[]>([]);
  public templateListObs$ = this.templateList.asObservable();

  nextTemplateList(templateList: TemplateUploadDTO[]): void {
    this.templateList.next(templateList);
  }
}
