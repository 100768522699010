import {RouterModule, Routes} from '@angular/router';
import {TemplateUploadComponent} from '../ui/template-upload/template-upload.component';
import {PageNotFoundComponent} from '../ui/shared-components/page-not-found/page-not-found.component';
import {LabelsComponent} from '../ui/labels/labels.component';

const SDP_ROUTES: Routes = [
  {path: 'labels', component: LabelsComponent, pathMatch: 'full'},
  {path: '404', component: PageNotFoundComponent},
  {path: '', component: TemplateUploadComponent, pathMatch: 'full'},
  {path: '**', redirectTo: '/404', pathMatch: 'full'}
];

export const routing = RouterModule.forRoot(SDP_ROUTES);
