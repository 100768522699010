import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sdp-sdp-button',
  templateUrl: './sdp-button.component.html',
  styleUrls: ['./sdp-button.component.scss']
})
export class SdpButtonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
