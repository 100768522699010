import * as vkbeautify from 'vkbeautify';
import {Injectable, Pipe, PipeTransform} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
@Pipe({
  name: 'xml'
})
export class XmlPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return vkbeautify.xml('');
    }
    return vkbeautify.xml(value);
  }
}
