export const SDP_TXT = {
  400: 'Bad request',
  403: 'Please login in in order to proceed further.',
  404: 'Not found',
  500: 'We encounter some problems processing your request. Please contact an administrator.',
  200: 'Your request was made with success.',
  409: 'Please change the object, this entity already exists.',
  GET_TEMPLATE: {
    ERROR_COUNTRY_CODE: 'Invalid or missing country code',
    ERROR_LABEL_ID: 'Invalid labelId',
    ERROR_LABEL_TYPE: 'Invalid label type, the length of type should not be higher than 4',
    ERROR_NO_DATA_PROVIDED: 'At least one field is mandatory',
    SUCCESS_GET_REQUEST: 'Templates were fetched with success',
    SUCCESS_GET_REQUEST_NO_TEMPLATES: 'No templates found for this data'
  },
  templateUpload: {
    missingNameOrTemplate: 'Your template name is missing or you should upload a template.',
    missingCountry: 'Please provide a country in order to save the template',
    templateUploadSuccess: 'Your template was uploaded with success.',
    templateSavedSuccess: 'Your template was successfully saved.',
    missingDimensions: 'Template must have mandatory css class: \'ms-template-page-\'',
    missingTemplateType: 'Template must have mandatory id: \'basic, set, variant, service, accessory, blister\''
  },
  renderImage: {
    missingXmlContent: 'Please add XML content',
    missingOutletId: 'OutletId is missing, outlet id was set to default: 1015',
    missingHtmlFile: 'Please upload an html file or select a saved one from dropdown.'
  },
  noTemplatesFound: 'No saved templates found. Please save a template.',
};

export const TEMPLATE_UPLOAD = {
  STEP_GENERATE_XML_A: 'Generate XML',
};

// localhost
// export const SDP_URL = 'http://localhost:9990/';
// export const ESL_URL = 'http://localhost:9991/api/';
// export const RENDERING_URL = 'http://localhost:3000/api/v1/';

// FOR LOCALHOST WITH TEST/DEV API CONFIG - dev config without nginx and apigee
// export const SDP_URL = 'https://store-data-presentation.dev.presto-esl.d-p.io/';
// export const ESL_URL = 'https://esl-link.dev.presto-esl.d-p.io/api/';
// export const RENDERING_URL = 'https://store-image-service.dev.presto-esl.d-p.io/api/v1/';
// export const PRODUCT_API = 'https://api-test.mediamarktsaturn.com/v2/sales-products/';
// export const PRICE_API = 'https://api-test.mediamarktsaturn.com/v1/consumer_prices/current';


// dev and prod with nginx and apigee
export const SDP_URL = '/sdp/';
export const ESL_URL = '/esl/';
export const PRODUCT_API = '/product-api/';
export const PRICE_API = '/price-api';
export const RENDERING_URL = '/rendering/';
export const SDP_FIFA_URL = '/sdp-fifa/';
export const ESL_FIFA_URL = '/esl-fifa/';
