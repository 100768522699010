import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpConfigService {

  constructor(private httpClient: HttpClient) {
  }

  /**
   * This is the httpRequest function
   * @param method - type string - ex: post/get/put/delete
   * @param url - type string
   * @param param - type object - payload to be sent to server
   * @param httpHeaders - type Object - headers to be set for request
   * @returns Observable with the server response
   */

  httpRequest(method: string, url: string, param: object, httpHeaders: any): Observable<object> {
    // todo: what happens with path params - remain to be refactored
    console.log(httpHeaders);
    if (!param) {
      return this.httpClient[method](url, {headers: httpHeaders.headers, responseType: httpHeaders?.responseType});
    }
    return this.httpClient[method](url, param, {headers: httpHeaders.headers, responseType: httpHeaders.responseType});
  }
}
