import {Injectable} from '@angular/core';
import {HttpHeaders, HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpConfigService} from './http-config.service';
import {ESL_FIFA_URL, SDP_FIFA_URL} from '../../shared/Constants';

@Injectable({
  providedIn: 'root'
})
export class HttpFifaService {
  private token: BehaviorSubject<string>;
  public $tokenObs: Observable<string>;
  private readonly requestParam: HttpParams;

  constructor(private httpConfigService: HttpConfigService) {
    this.token = new BehaviorSubject<string>(null);
    this.$tokenObs = this.token.asObservable();
    this.requestParam =  new HttpParams().set('grant_type', 'client_credentials');
  }

  getSDPFifaToken(): Observable<object> {
    return this.httpConfigService.httpRequest(
      'post',
      SDP_FIFA_URL,
      this.requestParam,
      this.configFifaHeaders());
  }

  getESLFifaToken(): Observable<object> {
    return this.httpConfigService.httpRequest(
      'post',
      ESL_FIFA_URL,
      this.requestParam,
      this.configFifaHeaders());
  }

  configFifaHeaders(): HttpHeaders {
    const fifaHeaders = new HttpHeaders();
    fifaHeaders.set('Content-type', 'application/x-www-form-urlencoded');
    return fifaHeaders;
  }
}
