import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, retry} from 'rxjs/operators';
import {UiErrorInterceptor} from '../../service/ui-error-interceptor';
import {DisplayErrorService} from '../../service/display-error.service';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorClass implements HttpInterceptor {
  constructor(private uiErrorInterceptor: UiErrorInterceptor,
              private displayErrorService: DisplayErrorService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // todo : check here for token and set headers ?
    return next.handle(req)
      .pipe(
        // todo: reset error
        // map((val) => {
        //   this.displayErrorService.setError('');
        //   return val;
        // }),
        retry(1),
        catchError((err: HttpErrorResponse) => {
          let errorMsg = '';
          if (err.error instanceof ErrorEvent) {
            // client error
            errorMsg = `Error: ${err.message}`;
            this.uiErrorInterceptor.setUiError({message: errorMsg, class: 'snackbar-error'});
          } else if (err.error && err.error.title) {
            errorMsg = `Status: ${err.error.status}, message: ${err.error.title}`;
            this.uiErrorInterceptor.setUiError({message: errorMsg, class: 'snackbar-error'});
          } else {
            // server err
            if (!err.error) {
              this.uiErrorInterceptor.setUiError({message: err.message, class: 'snackbar-error'});
            } else if (typeof err.error === 'string') {
              this.uiErrorInterceptor.setUiError({message: err.error, class: 'snackbar-error'});
            } else {
              this.uiErrorInterceptor.setUiError({message: 'Server error with status: ' + err.status, class: 'snackbar-error'});
            }
            this.displayErrorService.setError(JSON.stringify(err));
          }
          return throwError(errorMsg);
        })
      );
  }
}
