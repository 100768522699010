<div class="container">
  <div class="content flex-sp-btn">
    <div class="data__info__container">
      <sdp-xml-generator [imagePayload]="imageRenderPayload"
                         (imagePayloadEmitter)="getXmlDataFromForm($event)"></sdp-xml-generator>
      <div>
        <div class="upload__preview flex-v-sp-btn">
          <div>
            <div class="flex-sp-btn upload__content form__data__elem">
              <span class="upload__text">{{uploadTemplateText}}</span>
              <sdp-upload (fileEmmiter)="getUploadedFile($event)"></sdp-upload>
            </div>
          </div>
          <div>
            <h2 class="padding-btn-elem">Search templates by:</h2>
            <p class="padding-btn-elem">At least one of the values should be selected</p>
            <div>
              <input class="mb-16 sdp__input label-search-input save__template__input uppercase" type="text"
                     [(ngModel)]="countryCode" [maxLength]="2"
                     placeholder="Country: DE/ BE/ CH">
              <span>OR</span>
              <input class="mb-16 sdp__input label-search-input save__template__input" type="text"
                     [(ngModel)]="labelId" style="text-transform:uppercase"
                     placeholder="LabelId: C1AAA000">
              <span>OR</span>
              <input class="mb-16 sdp__input label-search-input save__template__input" type="text"
                     [(ngModel)]="templateName"
                     placeholder="Template name">
              <span>OR</span>
              <input class="mb-16 sdp__input label-search-input save__template__input" type="text"
                     [(ngModel)]="labelType"
                     placeholder="Label Type: 4.4 / 2.7 / 4.2. / 7.2.">
              <button [disabled]="!labelType && !templateName && !labelId && !countryCode"
                      mat-button class="sdp-button--active sdp-btn-min-width"
                      (click)="searchTemplatesBy()"
              >Search
              </button>
            </div>
          </div>
          <div>
            <sdp-dropdown class="dropdown__templates form__data__elem or" [dropdownContent]="dropdownContent"
                          (dropdownValueEmitter)="getTemplatesContentByName($event)"
            ></sdp-dropdown>
          </div>
          <div class="generate__preview__container">
            <button [disabled]="isPreviewDisabled()" mat-button class="sdp-button--active generate__img__btn"
                    (click)="generateImagePreview()">Preview image
            </button>
          </div>
          <div class="preview-card__footer">
            <sdp-dropdown class="dropdown__templates form__data__elem" [dropdownContent]="countriesList"
                          (dropdownValueEmitter)="setCountry($event)"
            ></sdp-dropdown>
            <div class="flex-col">
              <input mat-button type="text" placeholder="Template name" class="sdp__input save__template__input"
                     [(ngModel)]="name">
              <p>
                Template type (default value is basic_template):
                <sdp-dropdown class="dropdown__templates width-65" [dropdownContent]="templateTypes"
                              (dropdownValueEmitter)="setTemplateType($event)">
                </sdp-dropdown>
              </p>
              <p>
                Number of mandatory products (default value is 1):
                <sdp-dropdown class="dropdown__templates width-65" [dropdownContent]="mandatoryProducts"
                              (dropdownValueEmitter)="setMandatoryProducts($event)">
                </sdp-dropdown>
              </p>
            </div>

            <div class="flex-sp-btn">
              <button mat-button class="sdp-button--active sdp-btn-min-width" (click)="saveTemplate()"
                      [disabled]="isSaveDisabled()">
                Save template
              </button>

              <p [innerText]="updateTemplateMessage"></p>
              <button mat-button class="sdp-button--active sdp-btn-min-width" [disabled]="areTemplatesSelected()"
                      (click)="updateTemplate()">Update template
              </button>
            </div>
          </div>
        </div>
        <div>
        </div>
      </div>
    </div>
    <div class="upload__image__preview">
      <div class="img__error__container">
        <div class="upload__img__cont flex">
          <img class="uploaded-image" [src]="imagePreview | safeHtml" alt="Uploaded template">
        </div>
        <div class="error_area">
          <p class="padding-text error__display" [innerHTML]="error"></p>
        </div>
      </div>

    </div>
  </div>
</div>
