<div>
  <h2 class="padding-btn-elem">Search link by:</h2>
  <div class="form__data__elem flex-sp-btn bb-1 padding-btn-elem">
    <input mat-button type="text" placeholder="LabelId: C55699CC" class="sdp__input save__template__input"
           [(ngModel)]="labelId">
    <div>
      <button mat-button class="sdp-button--active mr-10"
              (click)="getLinkByLabelId()" [disabled]="areSearchByLabelDisabled()">
        Get link info
      </button>
      <button mat-button class="sdp-button--active mr-10"
              (click)="getLabelStatus()" [disabled]="areSearchByLabelDisabled()">
        Label status
      </button>
      <button mat-button class="sdp-button--active"
              (click)="getLiveLabel()" [disabled]="areSearchByLabelDisabled()">
        Live Label
      </button>
    </div>
  </div>
  <div class="form__data__elem padding-btn-elem bb-1">
    <h2 class="padding-btn-elem">Search link by:</h2>
    <div class="flex-sp-btn">
      <input mat-button type="number" placeholder="ProdId: 1234567"
             class="sdp__input label-search-input save__template__input"
             [(ngModel)]="productId">
      <span>AND</span>
      <input mat-button type="number" placeholder="OutletId: 606"
             class="sdp__input label-search-input save__template__input"
             [(ngModel)]="outletId">
      <span>OR</span>
      <input mat-button type="text" placeholder="Country: DE" maxlength="2"
             class="sdp__input label-search-input save__template__input uppercase"
             [(ngModel)]="countryCode">
    </div>
    <div class="flex-h-end padding-btn-elem">
      <button mat-button class="sdp-button--active label-search-input"
              (click)="getLinksByProductIdAndOrOutletIdAndOrCountry()"
              [disabled]="isSearchByProductOutletAndCountryDisabled()">
        Search links
      </button>
    </div>
  </div>
  <!--  todo: add as component-->
  <div class="bb-1">
    <h2 class="padding-btn-elem">Price info:</h2>
    <div class="flex-sp-btn">
      <input mat-button type="number" placeholder="ProdId: 1234567"
             class="sdp__input label-search-input save__template__input"
             [(ngModel)]="productId">
      <input mat-button type="number" placeholder="Outlet: 606"
             class="sdp__input label-search-input save__template__input uppercase"
             [(ngModel)]="outletId">
      <input mat-button type="text" placeholder="Default: mdng"
             class="sdp__input label-search-input save__template__input"
             [(ngModel)]="productIdType">
    </div>
    <div class="flex-h-end padding-btn-elem">
      <button mat-button class="sdp-button--active"
              (click)="getProductPriceDetails()"
              [disabled]="isSearchPriceInfoDisabled()">
        Get price info
      </button>
    </div>
  </div>
  <div class="bb-1">
    <h2 class="padding-btn-elem">Product info:</h2>
    <div class="flex-sp-btn">
      <input mat-button type="number" placeholder="ProdId: 1234567"
             class="sdp__input label-search-input save__template__input"
             [(ngModel)]="productId">
      <input mat-button type="text" placeholder="Lang: de" maxlength="2"
             class="sdp__input label-search-input save__template__input"
             [(ngModel)]="language">
      <input mat-button type="text" placeholder="Country: DE" maxlength="2"
             class="sdp__input label-search-input save__template__input uppercase"
             [(ngModel)]="countryCode">
    </div>
    <div class="flex-h-end padding-btn-elem">
      <button mat-button class="sdp-button--active"
              (click)="getProductDetails()"
              [disabled]="isSearchProductInfoDisabled()">
        Get product info
      </button>
    </div>
  </div>
  <div class="bb-1 padding-btn-elem">
    <h2 class="padding-bottom-elem">Campaign info (Status: VALID, NOT_STARTED, UNSUBSCRIBED)</h2>
    <div class="flex-sp-btn">
      <input mat-button type="number" placeholder="ProdId: 1234567"
             class="sdp__input label-search-input save__template__input"
             [(ngModel)]="productId">
      <input mat-button type="number" placeholder="OutletId: 606"
             class="sdp__input label-search-input save__template__input"
             [(ngModel)]="outletId">
      <input mat-button type="text" placeholder="VALID, NOT_STARTED, UNSUBSCRIBED"
             class="sdp__input label-search-input save__template__input uppercase"
             [(ngModel)]="campaignStatus">
    </div>
    <div class="flex-h-end padding-btn-elem">
      <button mat-button class="sdp-button--active"
              (click)="getCampaignByProductAndOutletId()"
              [disabled]="isCampaignButtonDisabled()">
        Get campaign
      </button>
    </div>
  </div>

  <div class="bb-1 padding-btn-elem">
    <h2 class="padding-bottom-elem">Warranty info based on language</h2>
    <div class="flex-sp-btn">
      <input mat-button type="number" placeholder="ProdGrp: 333"
             class="sdp__input label-search-input save__template__input"
             [(ngModel)]="productGroup">
      <span>OR</span>
      <input mat-button type="text" placeholder="Lang: de" maxlength="2"
             class="sdp__input label-search-input save__template__input"
             [(ngModel)]="language">
      <span>OR</span>
      <input mat-button type="text" placeholder="Country: DE" maxlength="2"
             class="sdp__input label-search-input save__template__input uppercase"
             [(ngModel)]="countryCode">
    </div>
    <div class="flex-h-end padding-btn-elem">
      <button mat-button class="sdp-button--active"
              (click)="getWarrantiesBy()"
              [disabled]="isWarrantyButtonDisabled()">
        Get warranties
      </button>
    </div>
  </div>

</div>

