export class TemplateUploadDTO {
  private name: string;
  private image: string;

  constructor(name: string, image: string) {
    this.image = image;
    this.name = name;
  }

  getName(): string {
    return this.name;
  }

  getImage(): string {
    return this.image;
  }
}
