import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {UiErrorInterceptor} from '../../../service/ui-error-interceptor';

@Component({
  selector: 'sdp-label-search',
  templateUrl: './label-search.component.html',
  styleUrls: ['./label-search.component.scss']
})
export class LabelSearchComponent implements OnInit {
  labelId: string;
  @Output() campaignGetEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() labelSearchEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Output() labelStatusEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Output() searchByProductOutletCountryEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() productInfoEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() priceInfoEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() warrantyEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() liveLabelEmitter: EventEmitter<any> = new EventEmitter<any>();
  productId: number;
  outletId: number;
  countryCode: string;
  language: string;
  productIdType = 'mdng';
  campaignStatus: 'VALID' | 'NOT_STARTED' | 'UNSUBSCRIBED';
  productGroup: number;

  constructor(private alertMessage: UiErrorInterceptor) {
  }

  ngOnInit(): void {
  }

  getCampaignByProductAndOutletId(): void {
    const statusUppercase = this.campaignStatus?.toUpperCase();

    // @ts-ignore
    // tslint:disable-next-line:no-bitwise
    if (statusUppercase !== 'NOT_STARTED' ^ statusUppercase !== 'VALID' ^ statusUppercase !== 'UNSUBSCRIBED') {
      this.alertMessage.setUiError({
        message: 'Status of campaing must be: VALID, NOT_STARTED, UNSUBSCRIBED',
        class: 'snackbar-error'
      });
      return;
    }
    const campaignData = {
      productId: this.productId,
      outletId: this.outletId,
      status: statusUppercase
    };
    this.campaignGetEmitter.emit(campaignData);
  }

  getLinkByLabelId(): void {
    if (this.labelId && this.labelId.length > 6) {
      this.labelSearchEmitter.emit(this.labelId);
    }
  }

  getLabelStatus(): void {
    if (this.labelId && this.labelId.length > 6) {
      this.labelStatusEmitter.emit(this.labelId);
    }
  }

  getLiveLabel(): void {
    if (this.labelId && this.labelId.length > 6) {
      this.liveLabelEmitter.emit(this.labelId);
    }
  }

  getProductDetails(): void {
    const prodInfoPayload = {
      productId: this.productId,
      countryCode: this.countryCode?.toUpperCase(),
      salesLine: 'media',
      language: this.language + '-' + this.countryCode?.toUpperCase(),
    };
    this.productInfoEmitter.emit(prodInfoPayload);
  }

  getProductPriceDetails(): void {
    const priceInfoPayload = {
      productId: this.productId,
      outletId: this.outletId,
      productIdType: this.productIdType
    };
    this.priceInfoEmitter.emit(priceInfoPayload);
  }

  getWarrantiesBy(): void {
    const warrantyInfoPayload = {
      productGroup: this.productGroup,
      language: this.language + '-' + this.countryCode.toUpperCase(),
      countryCode: this.countryCode.toUpperCase()
    };
    this.warrantyEmitter.emit(warrantyInfoPayload);
  }

  isSearchByProductOutletAndCountryDisabled(): boolean {
    return !this.productId && !this.outletId;
  }

  isCampaignButtonDisabled(): boolean {
    return !this.productId || !this.outletId || !this.campaignStatus;
  }

  isSearchPriceInfoDisabled(): boolean {
    return !this.productId || !this.outletId || !this.productIdType;
  }

  isSearchProductInfoDisabled(): boolean {
    return !this.productId || !this.countryCode || !this.language;
  }

  isWarrantyButtonDisabled(): boolean {
    return !this.productGroup;
  }

  areSearchByLabelDisabled(): boolean {
    return !this.labelId || this.labelId.length < 6;
  }

  getLinksByProductIdAndOrOutletIdAndOrCountry(): void {
    this.searchByProductOutletCountryEmitter.emit(
      {
        productId: this.productId,
        outletId: this.outletId,
        countryCode: this.countryCode?.toUpperCase()
      }
    );
  }
}
