export class ImagePreviewClientDTO {
  htmlTemplate: string;
  xmlContent: string;
  outputResolution: OutputResolution;
  labelId = 'B5480071';
  outletId: number;

  setOutputResolution(width: number, height: number): void {
    this.outputResolution = new OutputResolution(width, height);
  }
}

class OutputResolution {
  width: number;
  height: number;

  constructor(width, height) {
    this.width = width;
    this.height = height;
  }
}
