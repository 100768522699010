import {ProductDTO} from './product-dto';
import {IHierarchy} from './ihierarchy';
import {IBaseData} from './ibase-data';
import {IMarketing} from './imarketing';
import {IFeatureFrame} from './ifeature-frame';

export class ProductInformationDTO {
  // not blank
  private gtin13_primary: string;
  private name_basic: string;
  // not blank
  private name_customer_friendly: string;
  // not null
  private product_id: ProductDTO;

  hierarchy: IHierarchy;
  base_data: IBaseData;
  marketing: IMarketing;
  feature_frame: IFeatureFrame;


  getGtin13Primary(): string {
    return this.gtin13_primary;
  }

  setGtin13Primary(value: string): ProductInformationDTO {
    this.gtin13_primary = value;
    return this;
  }

  getNameBasic(): string {
    return this.name_basic;
  }

  setNameBasic(value: string): ProductInformationDTO {
    this.name_basic = value;
    return this;
  }

  getNameCustomerFriendly(): string {
    return this.name_customer_friendly;
  }

  setNameCustomerFriendly(value: string): ProductInformationDTO {
    this.name_customer_friendly = value;
    return this;
  }

  getProductId(): ProductDTO {
    return this.product_id;
  }

  setProductId(value: ProductDTO): ProductInformationDTO {
    this.product_id = value;
    return this;
  }

// todo : to be added;
  // private Hierarchy hierarchy = new Hierarchy();
  //
  // private BaseData base_data = new BaseData();
  //
  // private Marketing marketing;
  //
  // private FeatureFrame feature_frame = new FeatureFrame();
}
