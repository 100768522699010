import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {TemplateType} from "../../../data/http/dto/template-dto";

@Component({
  selector: 'sdp-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnChanges {

  @ViewChild('dropdownElement') dropdownElement;
  @Input() dropdownContent: any[] = [];
  @Output() dropdownValueEmitter: EventEmitter<string | number | TemplateType>;
  @Output() dropdownClickedEmitter: EventEmitter<boolean>;
  selectedValue = 'None';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dropdownContent && changes.dropdownContent.currentValue.length === 1 && changes.dropdownContent.currentValue[0] !== 'None') {
      this.selectedValue = changes.dropdownContent.currentValue[0];
      changes.dropdownContent.currentValue.unshift('None');
      return;
    }
    if (changes.dropdownContent.currentValue.length > 0 && changes.dropdownContent.currentValue[0] !== 'None') {
      changes.dropdownContent.currentValue.unshift('None');
      return;
    }
    if (changes.dropdownContent.currentValue?.length !== changes.dropdownContent?.previousValue?.length
      && typeof changes.dropdownContent.currentValue !== 'string') {
      this.selectedValue = 'None';
    }
  }

  constructor() {
    this.dropdownValueEmitter = new EventEmitter<string>();
    this.dropdownClickedEmitter = new EventEmitter<boolean>();
  }

  toggleShowDropdown(): void {
    this.dropdownElement.nativeElement.classList.toggle('show');
    this.dropdownClickedEmitter.emit();
  }

  selectValue(value): void {
    // todo: see how to beautify this part
    if (value === 'None') {
      this.dropdownValueEmitter.emit(null);
    } else {
      this.dropdownValueEmitter.emit(value);
    }
    this.selectedValue = value;
    this.toggleShowDropdown();
  }
}
