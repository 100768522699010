import {Component, OnInit} from '@angular/core';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
import {UiErrorInterceptor} from '../../../service/ui-error-interceptor';

@Component({
  selector: 'sdp-alert-message',
  templateUrl: './alert-message.component.html',
  styleUrls: ['./alert-message.component.scss']
})
export class AlertMessageComponent implements OnInit {
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(private snackBar: MatSnackBar,
              private uiInterceptor: UiErrorInterceptor) {
  }

  ngOnInit(): void {
    this.uiInterceptor.uiError.subscribe((alert) => {
      if (alert?.message) {
        this.openSnackBar(alert.message, alert.class);
      }
    });
  }

  openSnackBar(errorMessage: string, styleClass: string): void {
    this.snackBar.open(errorMessage, '', {
      duration: 4000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: styleClass
    });
  }
}
