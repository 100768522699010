import {JsonFormatter} from 'tslint/lib/formatters';

export class PriceInformationDTO {
  private currency: string;
  private productId: number;
  private outlet_id: number;
  private sales_price: string;
  private strike_price: string;


  getCurrency(): string {
    return this.currency;
  }

  setCurrency(value: string): void {
    this.currency = value;
  }

  getProductId(): number {
    return this.productId;
  }

  setProductId(value: number): PriceInformationDTO {
    this.productId = value;
    return this;
  }

  getOutletIid(): number {
    return this.outlet_id;
  }

  setOutletId(value: number): PriceInformationDTO {
    this.outlet_id = value;
    return this;
  }

  getSalesPrice(): string {
    return this.sales_price;
  }

  setSalesPrice(value: string): PriceInformationDTO {
    this.sales_price = value;
    return this;
  }
}
