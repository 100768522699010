import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'sdp-template-card',
  templateUrl: './template-card.component.html',
  styleUrls: ['./template-card.component.scss']
})
export class TemplateCardComponent implements OnInit {
  @Input() template;

  constructor() {
  }

  ngOnInit(): void {
  }

}
