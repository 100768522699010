import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'sdp-label-data-display',
  templateUrl: './label-data-display.component.html',
  styleUrls: ['./label-data-display.component.scss']
})
export class LabelDataDisplayComponent implements OnInit {
  @Input() data: any;
  @Input() image: string;
  constructor() { }


  ngOnInit(): void {

  }

}
