<div class="xml__section">
  <div class="xml__data__container">
    <div class="xml__form form__data__elem">
      <p class="subtitle cursor--pointer button--dark" (click)="toggleForm()"
         [innerHTML]="imageGeneratorSteps.STEP_GENERATE_XML_A"></p>
      <sdp-xml-form [ngClass]="isFormDisplayed ? 'show': 'none'" (formDataEmitter)="getXmlData($event)"></sdp-xml-form>
    </div>
    <div class="xml__form_textarea form__data__elem">
      <textarea [ngModel]="xmlString" (ngModelChange)="changeXmlData($event)" rows="32"
                placeholder="Xml content"></textarea>
    </div>
  </div>
</div>
