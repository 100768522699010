import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {HttpConfigService} from './http-config.service';
import {ArticleMockDataService} from './utils/article-mock-data.service';
import {TemplateDTO} from './dto/template-dto';
import {SDP_URL} from '../../shared/Constants';
import {ClientArticleDTO} from '../../ui/dto/client-article-dto';
import {HttpFifaService} from './http-fifa.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpSdpService {

  constructor(
    private httpConfig: HttpConfigService,
    private articleMockService: ArticleMockDataService,
    private httpClient: HttpClient,
    private httpFifaService: HttpFifaService,
    private httpConfigService: HttpConfigService
  ) {
  }

  configSdpHeaders(responseType = null): Promise<any> {
    return this.getHeaders().then(headers => {
      return {
        headers,
        responseType
      };
    });
  }

  getHeaders(): Promise<HttpHeaders> {
    return this.getFifaToken().then(token => {
      return new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('Content-Type', 'application/json')
        .set('x-debug-flag', 'dsadsasd');
    });
  }

  getFifaToken(): Promise<string> {
    return this.httpFifaService.getSDPFifaToken().toPromise()
      .then((resp: any) => {
        return resp.access_token;
      })
      .catch(() => console.log('Error while while fetching token for SDP'));
  }

  createTemplate(templateContent: TemplateDTO): Promise<Observable<object>> {
    return this.configSdpHeaders().then(headers => {
      return this.httpConfig.httpRequest(
        'post',
        this.getSdpUrl('store-product-labels/templates'),
        templateContent,
        headers);
    });
  }

  updateTemplate(templateContent: TemplateDTO): Promise<Observable<object>> {
    return this.configSdpHeaders().then(headers => {
      return this.httpConfig.httpRequest(
        'put',
        this.getSdpUrl('store-product-labels/templates' + '/' + templateContent.name),
        templateContent,
        headers);
    });
  }

  getArticleXML(clientArticleDTO: ClientArticleDTO): Promise<Observable<object>> {
    return this.configSdpHeaders('text').then(sdpHeaders => {
      return this.httpConfig.httpRequest(
        'get',
        this.getSdpUrl(`store-product-labels/util/xml?outlet_id=${clientArticleDTO.outletId}&product_id=${clientArticleDTO.productId}&label_id=${clientArticleDTO.labelId}&template_name=${clientArticleDTO.templateName}`),
        null,
        sdpHeaders
      );
    });
  }

  getTemplateByValues(labelId: string = '', name: string = '', labelType: string = '', countryCode: string = ''): Promise<Observable<object>> {
    return this.configSdpHeaders().then(sdpHeaders => {
      return this.httpClient.get(
        this.getSdpUrl(`store-product-labels/templates?label_id=${labelId}&name=${name}&type=${labelType}&country_code=${countryCode}`),
        {headers: sdpHeaders.headers});
    });
  }

  getCampaignsByProductIdAndOutletId(campaignData: any): Promise<Observable<any>> {
    return this.configSdpHeaders().then(sdpHeaders => {
      return this.httpClient.get(
        this.getSdpUrl(`campaigns/file-upload?product_id=${campaignData.productId}&outlet_id=${campaignData.outletId}&status=${campaignData.status}`),
        {headers: sdpHeaders.headers});
    });
  }

  getLiveLabelByLabelId(labelId: string): Promise<Observable<any>> {
    return this.configSdpHeaders('blob').then(sdpHeaders => {
      return this.httpConfigService.httpRequest(
        'get',
        this.getSdpUrl(`store-product-labels/rendered-images/label/${labelId}/live-label`),
        null,
        sdpHeaders);
    });
  }

  getWarrantiesBy(warrantyData: any): Promise<Observable<any>> {
    let url = 'store-product-labels/util/warranties?';
    if (warrantyData.productGroup && warrantyData.countryCode && warrantyData.language) {
      url += `productGroup=${warrantyData.productGroup}&country=${warrantyData.countryCode}&language=${warrantyData.language}`;
    } else if (warrantyData.productGroup && warrantyData.countryCode) {
      url += `productGroup=${warrantyData.productGroup}&country=${warrantyData.countryCode}`;
    } else {
      url += `productGroup=${warrantyData.productGroup}`;
    }

    return this.configSdpHeaders().then(sdpHeaders => {
      return this.httpClient.get(
        this.getSdpUrl(url),
        {headers: sdpHeaders.headers});
    });
  }

  getSdpUrl(customUrlPart = ''): string {
    return SDP_URL + customUrlPart;
  }
}
