import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TEMPLATE_UPLOAD} from '../../../shared/Constants';
import {ImagePreviewClientDTO} from '../dto/ImagePreviewClientDTO';
import {FormDataDTO} from './xml-form/dto/form-data-interface';
import {XmlPipe} from '../../../service/xml-pipe';

@Component({
  selector: 'sdp-xml-generator',
  templateUrl: './xml-generator.component.html',
  styleUrls: ['./xml-generator.component.scss']
})
export class XmlGeneratorComponent implements OnInit {
  imageGeneratorSteps;
  xmlString: string;
  @Input() imagePayload: ImagePreviewClientDTO;
  @Output() imagePayloadEmitter: EventEmitter<ImagePreviewClientDTO>;
  isFormDisplayed: boolean;

  constructor(private xmlPipe: XmlPipe) {
    this.imagePayloadEmitter = new EventEmitter<ImagePreviewClientDTO>();
    this.isFormDisplayed = false;
  }

  ngOnInit(): void {
    this.xmlString = '';
    this.imageGeneratorSteps = TEMPLATE_UPLOAD;
  }

  changeXmlData(text): void {
    if (!text || !text.trim()) {
      this.xmlString = '';
      return;
    }
    this.xmlString = text;
    this.imagePayload.xmlContent = text;
    // SET A DEFAULT ONE
    if (!this.imagePayload.outletId) {
      this.imagePayload.outletId = 1015;
    }
    this.imagePayloadEmitter.emit(this.imagePayload);
  }

  getXmlData(formData: FormDataDTO): void {
    this.xmlString = this.xmlPipe.transform(formData.xmlData);
    this.imagePayload.xmlContent = formData.xmlData;
    this.imagePayload.outletId = formData.outletId;
  }

  toggleForm(): void {
    this.isFormDisplayed = !this.isFormDisplayed;
  }
}
