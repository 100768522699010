import {Injectable} from '@angular/core';
import {HttpConfigService} from '../../../data/http/http-config.service';
import {HttpHeaders} from '@angular/common/http';
import {HttpFifaService} from '../../../data/http/http-fifa.service';
import {ESL_URL} from '../../../shared/Constants';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LabelsService {

  constructor(private httpConfig: HttpConfigService,
              private httpFifaService: HttpFifaService) {
  }

  configSdpHeaders(responseType = null): Promise<any> {
    return this.getESLFifaToken().then(token => {
      const headers = new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('Content-Type', 'application/json')
        .set('x-debug-flag', 'esl');
      return {
        headers,
        responseType
      };
    });
  }

  getESLUrl(customUrlPart = ''): string {
    return ESL_URL + customUrlPart;
  }

  getESLFifaToken(): Promise<string> {
    return this.httpFifaService.getESLFifaToken().toPromise()
      .then((resp: any) => {
        return resp.access_token;
      })
      .catch(error => console.log('Error while while fetching token for ESL', error));
  }

  getEslInfoByLabelId(labelId: string): Promise<Observable<any>> {
    return this.configSdpHeaders().then(sdpHeaders => {
      return this.httpConfig.httpRequest(
        'get',
        this.getESLUrl(`esl-links/${labelId}`),
        null,
        sdpHeaders);
    });
  }

  getStatusOfLabel(labelId: string): Promise<Observable<object>>{
    return this.configSdpHeaders().then(sdpHeaders => {
      return this.httpConfig.httpRequest(
        'get',
        this.getESLUrl(`store-product-labels/${labelId}/status`),
        null,
        sdpHeaders);
    });
  }

  getLinksByProductIdAndOrOutletIdAndOrCountryId(data: any): Promise<Observable<object>> {
    let customUrl = '';
    if (data.productId && data.outletId) {
      customUrl = `esl-links?productId=${data.productId}&outletId=${data.outletId}`;
    } else if (data.productId && data.countryCode) {
      customUrl = `esl-links?productId=${data.productId}&countryCode=${data.countryCode}`;
    } else {
      customUrl = `esl-links?$productId=${data.productId}`;
    }

    return this.configSdpHeaders().then(sdpHeaders => {
      return this.httpConfig.httpRequest(
        'get',
        this.getESLUrl(customUrl),
        null,
        sdpHeaders);
    });
  }
}
