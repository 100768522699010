import {Component, OnInit} from '@angular/core';
import {SDP_TXT, TEMPLATE_UPLOAD} from '../../shared/Constants';
import {HttpSdpService} from '../../data/http/http-sdp.service';
import {ImagePreviewClientDTO} from './dto/ImagePreviewClientDTO';
import {ImageGeneratorPayloadService} from './services/image-generator-payload.service';
import {TemplateDTO, TemplateType} from '../../data/http/dto/template-dto';
import {UiErrorInterceptor} from '../../service/ui-error-interceptor';
import {HttpRenderingEngineService} from '../../data/http/http-rendering-engine.service';
import {TemplateUploadService} from './services/template-upload-service';
import {ITemplateDTO, ITemplateList} from '../interfaces/ITemplateDTO';

@Component({
  selector: 'sdp-template-upload',
  templateUrl: './template-upload.component.html',
  styleUrls: ['./template-upload.component.scss']
})
export class TemplateUploadComponent implements OnInit {
  dropdownContent: string[];
  countriesList: string[] = ['AT','DE', 'CH', 'SE', 'BE', 'TR', 'NL', 'LU', 'ES'];
  imagePreview: string;
  imageRenderPayload: ImagePreviewClientDTO;
  isImgPreviewDisplayed = false;
  name: string;
  templateUploadText;
  uploadTemplateText: string;
  error: string;
  private selectedTemplateByName: any = {};
  templateCreationPayload: TemplateDTO;
  updateTemplateMessage = '';
  countryCode: string;
  labelType: string;
  labelId = '';
  templateName: string;
  templates: ITemplateDTO[];
  templateTypes: TemplateType[] = Object.values(TemplateType);
  mandatoryProducts: number[] = [1, 2, 3, 4, 5];

  constructor(private httpSdpService: HttpSdpService,
              private imagePreviewService: ImageGeneratorPayloadService,
              private alertMessage: UiErrorInterceptor,
              private httpRenderingService: HttpRenderingEngineService,
              private templateService: TemplateUploadService
  ) {
    this.templateUploadText = TEMPLATE_UPLOAD;
  }

  ngOnInit(): void {
    this.dropdownContent = [];
    this.imageRenderPayload = new ImagePreviewClientDTO();
    this.templateCreationPayload = new TemplateDTO('', '', null, null, TemplateType.basic_template, '', 1);
    this.imagePreview = '';
    this.uploadTemplateText = 'Upload template';
    this.error = '';
  }

  generateImagePreview(): void {
    if (this.templateService.isImagePayloadInValid(this.imageRenderPayload)) {
      this.alertMessage.setUiError({
        message: this.templateService.isImagePayloadInValid(this.imageRenderPayload) as string,
        class: 'snackbar-error'
      });
      return;
    }
    this.httpRenderingService.getImagePreview(this.imageRenderPayload).subscribe((response) => {
      const pngString = btoa(String.fromCharCode.apply(null, new Uint8Array(response)));
      this.imagePreview = 'data:image/jpg;base64, ' + pngString;
      this.alertMessage.setUiError({
        message: 'Image generated',
        class: 'snackbar-success'
      });
    });
  }

  isPreviewDisabled(): boolean {
    return (!this.imageRenderPayload.xmlContent || !this.imageRenderPayload.xmlContent.trim())
      || (!this.imageRenderPayload.htmlTemplate || !this.imageRenderPayload.htmlTemplate.trim());
  }

  isSaveDisabled(): boolean {
    return (typeof this.name === 'undefined' || !this.name)
      || !this.templateCreationPayload.content;
  }

  areTemplatesSelected(): boolean {
    return !this.templateCreationPayload.content || !this.selectedTemplateByName || !this.selectedTemplateByName.htmlContent;
  }

  getUploadedFile(data: any): void {
    const dimensionsAndType = this.templateService.getAndCheckTemplateDimensionsAndType(data.htmlContent);
    if (!dimensionsAndType.dimensions[0] || !dimensionsAndType.dimensions[1]) {
      return;
    }
    this.imagePreview = '';
    this.templateCreationPayload.width = dimensionsAndType.dimensions[0];
    this.templateCreationPayload.height = dimensionsAndType.dimensions[1];
    this.imageRenderPayload.setOutputResolution(dimensionsAndType.dimensions[0], dimensionsAndType.dimensions[1]);
    this.templateCreationPayload.type = dimensionsAndType.templateType;
    this.templateCreationPayload.content = data.htmlContent;
    this.imageRenderPayload.htmlTemplate = data.htmlContent;
    if (this.selectedTemplateByName?.name) {
      this.updateTemplateMessage = `Update template: ${this.selectedTemplateByName.name}`;
    }
    this.imagePreviewService.nextImageGeneratorPayload(this.imageRenderPayload);
    const templateName = data.templatePath.split('\\');
    this.uploadTemplateText = templateName[templateName.length - 1];
    this.alertMessage.setUiError({
      message: SDP_TXT.templateUpload.templateUploadSuccess,
      class: 'snackbar-success'
    });
  }

  getTemplatesContentByName(name: string, template: ITemplateDTO = null): void {
    if (name === null) {
      this.resetPayload();
      return;
    }

    if (!template && this.templates && this.templates.length > 0) {
      template = this.templates.find((t) => {
        return t.name === name;
      });
    }

    this.selectedTemplateByName.htmlContent = template.content;
    this.selectedTemplateByName.name = name;
    this.templateCreationPayload.country = template.country;
    if (this.templateCreationPayload?.content) {
      this.updateTemplateMessage = `Update template: ${name}`;
    }
    this.imageRenderPayload.htmlTemplate = template.content;
    const dimensionsAndType = this.templateService.getAndCheckTemplateDimensionsAndType(template.content);
    this.imageRenderPayload.setOutputResolution(dimensionsAndType.dimensions[0], dimensionsAndType.dimensions[1]);
  }

  filterTemplatesByName(): ITemplateDTO {
    return null;
  }

  getXmlDataFromForm(formData: ImagePreviewClientDTO): void {
    this.imageRenderPayload.outletId = formData.outletId;
    this.imageRenderPayload.xmlContent = formData.xmlContent;
    this.error = '';
  }

  saveTemplate(): void {
    if (!this.templateService.checkForSaveTemplatePayload(this.name, this.templateCreationPayload)) {
      return;
    }
    this.error = '';
    this.templateCreationPayload.name = this.name;

    this.httpSdpService
      .createTemplate(this.templateCreationPayload)
      .then((req) => {
        req.subscribe(() => {
          this.dropdownContent.push(this.name);
          this.name = '';
          this.alertMessage.setUiError({
            message: SDP_TXT.templateUpload.templateSavedSuccess,
            class: 'snackbar-success'
          });
        });
      });
  }

  searchTemplatesBy(): void {
    if (this.labelId && this.labelId.length < 5) {
      this.alertMessage.setUiError({
        message: SDP_TXT.GET_TEMPLATE.ERROR_LABEL_ID,
        class: 'snackbar-error'
      });
      return;
    } else if (this.labelType && (typeof parseFloat(this.labelType) !== 'number' || this.labelType.length > 4)) {
      this.alertMessage.setUiError({
        message: SDP_TXT.GET_TEMPLATE.ERROR_LABEL_TYPE,
        class: 'snackbar-error'
      });
      return;
    } else if (!this.templateName && !this.labelId && !this.labelType && !this.countryCode) {
      this.alertMessage.setUiError({
        message: SDP_TXT.GET_TEMPLATE.ERROR_NO_DATA_PROVIDED,
        class: 'snackbar-error'
      });
      return;
    }
    this.httpSdpService.getTemplateByValues(this.labelId.toUpperCase(), this.templateName, this.labelType, this.countryCode)
      .then(req => {
        req.subscribe((templatesDto: ITemplateList) => {
          this.setTemplatesToUI(templatesDto);
          this.templates = [];
          this.templates = templatesDto.templates;
        }, error => {
          this.alertMessage.setUiError({
            message: error.messag,
            class: 'snackbar-error'
          });
        });
      });
  }

  setCountry(country: string): void {
    if (country) {
      this.templateCreationPayload.country = country;
    }
  }

  setTemplateType(templateType: TemplateType) {
    this.templateCreationPayload.type = templateType ?
      templateType :
      TemplateType.basic_template;
  }

  setMandatoryProducts(mandatoryProducts: number) {
    this.templateCreationPayload.mandatoryProducts = mandatoryProducts ?
      mandatoryProducts :
      1;
  }

  setTemplatesToUI(templatesDto: ITemplateList): void {
    if (templatesDto.templates && templatesDto.templates.length === 0) {
      this.alertMessage.setUiError({
        message: SDP_TXT.GET_TEMPLATE.SUCCESS_GET_REQUEST_NO_TEMPLATES,
        class: 'snackbar-success'
      });
      this.dropdownContent = [];
      return;
    } else if (templatesDto.templates && templatesDto.templates.length === 1) {
      this.getTemplatesContentByName(templatesDto.templates[0].name, templatesDto.templates[0]);
      this.dropdownContent = [];
      this.dropdownContent.push(templatesDto.templates[0].name);
    } else if (templatesDto.templates.length > 1) {
      this.dropdownContent = [];
      templatesDto.templates.forEach((t) => {
        this.dropdownContent.push(t.name);
      });
    }

    this.alertMessage.setUiError({
      message: SDP_TXT.GET_TEMPLATE.SUCCESS_GET_REQUEST,
      class: 'snackbar-success'
    });
  }

  updateTemplate(): void {
    if (this.name && this.name.trim()) {
      this.templateCreationPayload.name = this.name;
    } else {
      this.templateCreationPayload.name = this.selectedTemplateByName.name;
    }
    this.httpSdpService
      .updateTemplate(this.templateCreationPayload)
      .then((req) => {
        req.subscribe(() => {
          this.name = '';
          this.updateTemplateMessage = '';
          this.templateCreationPayload = new TemplateDTO('', '', null, null, TemplateType.basic_template, '', 1);
          this.alertMessage.setUiError({
            message: SDP_TXT.templateUpload.templateSavedSuccess,
            class: 'snackbar-success'
          });
        });
      });
  }

  resetPayload(): void {
    this.selectedTemplateByName.htmlContent = '';
    this.selectedTemplateByName.name = '';
    this.imageRenderPayload.htmlTemplate = '';
    this.updateTemplateMessage = '';
  }

  toggleIsPreviewDisplayed(): void {
    this.isImgPreviewDisplayed = !this.isImgPreviewDisplayed;
  }
}
