import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ImagePreviewClientDTO} from '../dto/ImagePreviewClientDTO';

@Injectable({
  providedIn: 'root'
})
export class ImageGeneratorPayloadService {
  private imageGeneratorPayload = new BehaviorSubject<ImagePreviewClientDTO>(null);
  public currentImageGeneratorPayload = this.imageGeneratorPayload.asObservable();

  nextImageGeneratorPayload(imageGeneratorPayload: ImagePreviewClientDTO): void {
    this.imageGeneratorPayload.next(imageGeneratorPayload);
  }

  constructor() {
  }
}
