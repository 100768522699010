import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TemplateUtilService {
  TEMPLATE_UPLOAD_CLASS_ID = {
    dimensions: 'ms-template-page-',
    blister_type: 'blister_template',
    set_type: 'set_template',
    accessory_type: 'accessory_template',
    variant_type: 'variant_template',
    service_type: 'service_template',
    basic_type: 'basic_template',
    STEP_GENERATE_HTML_B: '<strong class="strong">B.</strong> Select a template from dropdown.',
  };

  extractDimensionFromTemplate(htmlTemplate: string): number[] {
    const classIndex = htmlTemplate.indexOf(this.TEMPLATE_UPLOAD_CLASS_ID.dimensions);
    if (classIndex === -1) {
      return null;
    }
    return htmlTemplate
      .substring(classIndex + 17, classIndex + 24)
      .split('x')
      .map((value: string) => {
        return parseInt(value, 10);
      });
  }

  searchAndSetTemplateType(htmlTemplate: string): string {
    if (htmlTemplate.indexOf(this.TEMPLATE_UPLOAD_CLASS_ID.blister_type) !== -1) {
      return this.TEMPLATE_UPLOAD_CLASS_ID.blister_type;
    } else if (htmlTemplate.indexOf(this.TEMPLATE_UPLOAD_CLASS_ID.set_type) !== -1) {
      return this.TEMPLATE_UPLOAD_CLASS_ID.set_type;
    } else if (htmlTemplate.indexOf(this.TEMPLATE_UPLOAD_CLASS_ID.accessory_type) !== -1) {
      return this.TEMPLATE_UPLOAD_CLASS_ID.accessory_type;
    } else if (htmlTemplate.indexOf(this.TEMPLATE_UPLOAD_CLASS_ID.variant_type) !== -1) {
      return this.TEMPLATE_UPLOAD_CLASS_ID.variant_type;
    } else if (htmlTemplate.indexOf(this.TEMPLATE_UPLOAD_CLASS_ID.service_type) !== -1) {
      return this.TEMPLATE_UPLOAD_CLASS_ID.service_type;
    } else {
      return this.TEMPLATE_UPLOAD_CLASS_ID.basic_type;
    }
  }
}
