import {Component, OnDestroy} from '@angular/core';
import {LabelsService} from './services/labels.service';
import {ProductInfoService} from './services/product-info.service';
import {PriceInfoService} from './services/price-info.service';
import {HttpSdpService} from '../../data/http/http-sdp.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'sdp-labels',
  templateUrl: './labels.component.html',
  styleUrls: ['./labels.component.scss']
})
export class LabelsComponent implements OnDestroy {
  requestResponse: any;
  imageResponse: any;
  private campaignSubs: Subscription;
  private linkInfoSubs: Subscription;
  private linkStatusSubs: Subscription;
  private linkByProdAndOutletAndCountrySubs: Subscription;
  private priceSubs: Subscription;
  private productSubs: Subscription;
  private warrantySub: Subscription;
  private liveLabelSub: Subscription;

  constructor(private labelsService: LabelsService,
              private productService: ProductInfoService,
              private priceService: PriceInfoService,
              private sdpService: HttpSdpService) {

  }

  ngOnDestroy(): void {
    this.campaignSubs?.unsubscribe();
    this.linkInfoSubs?.unsubscribe();
    this.linkStatusSubs?.unsubscribe();
    this.linkByProdAndOutletAndCountrySubs?.unsubscribe();
    this.priceSubs?.unsubscribe();
    this.productSubs?.unsubscribe();
    this.warrantySub?.unsubscribe();
    this.liveLabelSub?.unsubscribe();
  }

  // CAMPAIGNS
  getCampaignByProductIdAndOutletId(campaignData: any): void {
    this.sdpService.getCampaignsByProductIdAndOutletId(campaignData).then((req) => {
      req.subscribe(resp => {
        this.setDataToDisplay(false, resp);
      });
    });
  }

  // LABEL INFO
  getLinkByLabelId(labelId: string): void {
    // 'C1000015'/

    this.labelsService.getEslInfoByLabelId(labelId).then((req) => {
      req.subscribe(resp => {
        this.setDataToDisplay(false, resp);
      });
    });
  }

  getStatusOfLabel(labelId: string): void {
    this.labelsService.getStatusOfLabel(labelId).then((req) => {
      req.subscribe(resp => {
        this.setDataToDisplay(false, resp);
      });
    });
  }

  getLinksByProductIdAndOrOutletIdAndOrCountryId(data: any): void {
    this.labelsService.getLinksByProductIdAndOrOutletIdAndOrCountryId(data).then((req) => {
      req.subscribe(resp => {
        this.setDataToDisplay(false, resp);
      });
    });
  }

  // PRODUCT INFO
  getProductInfo(productData: any): void {
    this.productService.getProductInfo(productData).then((req) => {
      req.subscribe(resp => {
          this.setDataToDisplay(false, resp);
        },
        (error: any) => {
          console.log('Error ' + error);
        });
    });
  }

  // PRICE INFO
  getPriceInfo(priceData: any): void {
    this.priceSubs = this.priceService.getPriceInfo(priceData).subscribe((res) => {
      this.setDataToDisplay(false, res);
    });
  }

  // WARRANTIES
  getWarrantiesBy(warrantyPayload): void {
    this.sdpService.getWarrantiesBy(warrantyPayload).then((req) => {
      req.subscribe(resp => {
        this.setDataToDisplay(false, resp);
      });
    });
  }

  getLiveLabelByLabelId(labelId: string): void {
    this.sdpService.getLiveLabelByLabelId(labelId).then((req) => {
      req.subscribe(resp => {
        this.setDataToDisplay(true, URL.createObjectURL(resp));
      });
    });
  }

  setDataToDisplay(isImageResponse: boolean, res: any): void {
    if (isImageResponse) {
      this.requestResponse = '';
      this.imageResponse = res;
    } else {
      this.requestResponse = res;
      this.imageResponse = '';
    }
  }
}
